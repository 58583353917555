import { useState, useEffect } from "react";

const useHandleResize = () => {
	// if (typeof window === "undefined") return null;

	const [_document, setDocument] = useState(null);
	const [currentScreenResolution, setCurrentScreenResolution] = useState(360);

	useEffect(() => {
		setDocument(document);
		const handleResizeEvent = () => {
			setCurrentScreenResolution(document.body.clientWidth);
		};
		window.addEventListener("resize", handleResizeEvent);
	}, []);

	return currentScreenResolution + 17;
};

export default useHandleResize;
