import { ImageSlide } from "@/app/types/ImageSlide.type";

export const getImageCropSlides = (image: ImageSlide, aspectRatio: string) => {
	switch (aspectRatio) {
		case "16:9":
			return image.corte_16_9;
		case "1:1":
			return image.corte_1_1;
		case "3:4":
			return image.corte_3_4;
		case "4:3":
			return image.corte_4_3;
		default:
			return image.corte_16_9;
	}
};
