"use client";
import { useRef, useState, CSSProperties } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	FreeMode,
	Navigation,
	Pagination,
	Thumbs,
	Keyboard,
} from "swiper/modules";
import Image from "next/image";

import useHandleResize from "@/hooks/useHandleResize";
import CardVideoStoryPage from "@/app/components/molecules/cards/CardVideoStoryPage/CardVideoStoryPage";
import swipperVideoPLaylistBrakpoints from "@/app/components/molecules/StoryPageMedia/helpers/swiperBrakpoints";
import { getImageCropSlides } from "@/app/helpers/image/getImageCropSlides";

import style from "@/app/styles/Molecules/MultiImage.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

type Props = {
	cardItem?: any;
	isOpening?: boolean;
	typeMedia?: string;
	aspectRatio?: string;
	showPagination?: boolean;
	showNavigation?: boolean;
	showZoomOption?: boolean;
	isLiveBlog?: boolean;
};

export default function MultiImage({
	isOpening,
	cardItem,
	typeMedia,
	aspectRatio = "16/9",
	showPagination = false,
	showNavigation = true,
	showZoomOption = false,
	isLiveBlog,
}: Props) {
	const imagesArray = cardItem?.images;
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [sliderIndex, setSliderIndex] = useState<number>(0);
	const [sliderIndexThumbs, setSliderIndexThumbs] = useState<number>(0);
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [initialImage, setInitialImage] = useState(0);
	const swiperRef = useRef<any>(null);
	const currentResolution: number = useHandleResize() ?? 0;
	const modulesSwiper = [FreeMode, Thumbs, Keyboard, Navigation];
	// if (showNavigation) {
	// 	modulesSwiper.push(Navigation);
	// }
	if (showPagination) {
		modulesSwiper.push(Pagination);
	}
	const handleOpenZoom = (index: number) => {
		setInitialImage(index);
		setIsPreviewOpen(true);
	};
	const handleClosePreview = () => {
		setIsPreviewOpen(false);
	};

	return (
		<div className={`${style["multiImage__multiImageWrapper"]}`}>
			<Swiper
				keyboard={{
					enabled: true,
				}}
				// spaceBetween={10}
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				thumbs={{ swiper: thumbsSwiper }}
				pagination={{
					clickable: true,
					el: `.${style["multiImage__bullets"]}`,
				}}
				modules={modulesSwiper}
				watchSlidesProgress={true}
				className={`mySwiper ${showPagination ? "pagination" : ""}`}
				onSlideChange={(e) => {
					swiperRef?.current?.swiper.slideTo(e.activeIndex);
					setSliderIndex(e.activeIndex);
				}}
			>
				{imagesArray
					?.filter((item: any) => item.image !== null)
					?.map((image: any, index: number) => {
						return (
							<SwiperSlide key={index}>
								<figure
									className={`${style["multiImage__imageWrapper"]}`}
									style={
										{
											"--aspect-ratio": `${
												aspectRatio === "1:1"
													? "1/1"
													: aspectRatio === "16:9"
													? "16/9"
													: aspectRatio === "3:4"
													? "3/4"
													: aspectRatio === "4:3"
													? "4/3"
													: ""
											}`,
										} as CSSProperties
									}
								>
									<div className={`${style["multiImage__image"]}`}>
										<Image
											src={
												getImageCropSlides(image?.image, aspectRatio) ??
												"/assets/placeholder_.svg"
											}
											alt={image?.image?.alt}
											width={987}
											height={557}
											sizes="(min-width: 1440px) 58vw, (min-width: 1280px) 65vw, (min-width: 1024px) 45.2vw, (min-width: 768px) 45vw, (min-width: 720px) 40vw, (min-width: 430px) 37vw, (min-width: 390px) 35vw, (min-width: 375px) 50vw, 26vw"
											fetchPriority="high"
											loading="lazy"
											// placeholder="blur"
											// blurDataURL={`${
											// 	process.env.NEXT_PUBLIC_BLURIMAGE ||
											// 	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
											// }`}
											quality={60}
										/>
										{/* {showZoomOption && (
											<ButtonOpen
												blockData={{
													contentTitle: "open zoom",
												}}
												cardPosition={index}
												action={() => handleOpenZoom(index)}
												className={`${style["multiImage__zoomButton"]}`}
												title={"open zoom"}
											>
												<Icon name="openZoom" />
											</ButtonOpen>
										)} */}
									</div>
									<figcaption
										className={`${style["multiImage__captionWrapperImage"]}`}
									>
										<p
											className={`${
												isLiveBlog
													? style["multiImage__liveblog__captionImage"]
													: style["multiImage__captionImage"]
											}`}
										>
											{image?.image?.caption}
										</p>
									</figcaption>
								</figure>
							</SwiperSlide>
						);
					})}
				<div
					className={`${style["multiImage__bullets"]} ${
						aspectRatio === "1:1" ? style["multiImage__bullets--1_1"] : ""
					}`}
				/>
				<div>
					<div
						className={`swiper-button-prev ${style["multiImage__liveblog--navigationButton"]} ${style["multiImage__liveblog--prevButton"]}`}
						style={{
							border: "none",
							background: "rgba(132, 132, 132, 0.30)",
						}}
					/>

					<div
						className={`swiper-button-next 
						${style["multiImage__liveblog--navigationButton"]} 
						${style["multiImage__liveblog--nextButton"]} 
						${
							aspectRatio === "1:1"
								? style["multiImage__liveblog--nextButton-1_1"]
								: aspectRatio === "4:3"
								? style["multiImage__liveblog--nextButton-4_3"]
								: aspectRatio === "3:4"
								? style["multiImage__liveblog--nextButton-3_4"]
								: ""
						}
					`}
						style={{
							border: "none",
							background: "rgba(132, 132, 132, 0.30)",
						}}
					/>
				</div>
			</Swiper>
			{isOpening && !isLiveBlog && (
				<Swiper
					keyboard={{
						enabled: true,
					}}
					spaceBetween={8}
					pagination={{
						clickable: true,
					}}
					// @ts-ignore
					onSwiper={setThumbsSwiper}
					freeMode={true}
					watchSlidesProgress={true}
					modules={[FreeMode, Keyboard]}
					className={`${style["multiImage__swiperMultiImage"]} ${style['multiImage__swiperMultiImage--padding']}`}
					ref={swiperRef}
					breakpoints={swipperVideoPLaylistBrakpoints(currentResolution)}
					onSlideChange={(e) => {
						swiperRef?.current?.swiper.slideTo(e.activeIndex);
						setSliderIndexThumbs(e.activeIndex);
					}}
				>
					{imagesArray
						?.filter((item: any) => item.image !== null)
						?.map((image: any, index: number) => {
							return (
								<SwiperSlide
									onClick={() => {
										swiperRef?.current?.swiper.slideTo(index);
									}}
									style={{ width: "fit-content" }}
									key={index}
								>
									<CardVideoStoryPage
										typeOfMedia={typeMedia}
										image={image}
										indexVideoCard={index}
										indexVideoPlay={sliderIndex}
									/>
								</SwiperSlide>
							);
						})}
				</Swiper>
			)}
			{/* {isPreviewOpen && (
				<div className={`${style["multiImage__preview"]}`}>
					<Icon
						name="closePreview"
						className={`${style["multiImage__closePreviewButton"]}`}
						onClick={handleClosePreview}
					/>

					<figure className={`${style["multiImage__figurePreview"]}`}>
						<Image
							src={
								getImageCropSlides(
									imagesArray[initialImage]?.image,
									aspectRatio
								) ?? "/assets/placeholder_.svg"
							}
							width={500}
							height={500}
							sizes="(max-width:834px) 100vw"
							quality={75}
							alt={"alt-text"}
							className={`${style["multiImage__image"]} ${style["multiImage__previewImage"]}`}
							style={
								{
									"--heightImage": `${
										aspectRatio === "16:9"
											? "auto"
											: aspectRatio === "4:3"
											? "auto"
											: "80vh"
									}`,
									"--widthImage": `${
										aspectRatio === "16:9"
											? "75vw"
											: aspectRatio === "4:3"
											? "100vw"
											: "auto"
									}`,
									"--aspect-ratio": `${
										aspectRatio === "1:1"
											? "1/1"
											: aspectRatio === "16:9"
											? "16/9"
											: aspectRatio === "3:4"
											? "3/4"
											: aspectRatio === "4:3"
											? "4/3"
											: ""
									}`,
								} as CSSProperties
							}
						/>
					</figure>
				</div>
			)} */}
		</div>
	);
}
