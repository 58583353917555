"use client";
import { useEffect, useMemo, useCallback } from "react";
import { usePathname } from "next/navigation";
import { BreadcrumbProps } from "./types/Breadcrumb";
import bloque from "@/app/styles/Molecules/BreadCrumb.module.scss";
import { widthTrailingSlash } from "@/app/helpers/trailingSlash/widthTrailingSlash";
import Icon from "../../atoms/Icon/Icon";

export default function Breadcrumb({
	getLasted = false,
	themeColor = "light",
	typeRender = "default",
	specialPath = "",
	currentPath = null,
}: BreadcrumbProps) {
	const pathName = usePathname();
	let crumblist: any[] = [];
	let auxCrumblist: any[] = [];
	const toTitleCase = (str: any) => {
		return (
			str
				.replace(/\w\S*/g, (txt: any) => {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				})
				// Las palabras incompletas son para que sea indistinto si inicia con mayus o no
				// Ejemplo -> "odigo" remplazará Código o código
				.replace(/-/g, " ")
				.replace("mexico", "México")
				.replace("Mexico", "México")
				.replace("oliticas", "olíticas")
				.replace("eplica", "éplica")
				.replace("odigo", "ódigo")
				.replace("etica", "ética")
				.replace("ecnologia", "ecnología")
				.replace("conomia", "conomía")
				.replace("manana", "Mañana")
		);
	};

	// Get the pathname and split it into an array of strings (the crumbs) and the last crumb
	const getDefaultTextGenerator = useCallback((subpath: string) => {
		return {}[subpath] || toTitleCase(subpath);
	}, []);

	// Generate the crumbs
	const generatePathParts = (pathStr: any) => {
		const pathWithoutQuery = pathStr
			.replace(/videos\/|episodios\//, "")
			.split("?")[0];
		return pathWithoutQuery.split("/").filter((v: string) => v.length > 0);
	};
	const generateBreadcrumbs = (typeRender: string) => {
		const asPathNestedRoutes = generatePathParts(currentPath ?? pathName);
		crumblist = asPathNestedRoutes.map((subpath: any, idx: any) => {
			const href = "/" + asPathNestedRoutes.slice(0, idx + 1).join("/");
			return {
				href,
				text: getDefaultTextGenerator(subpath)
					.replace("Noticieros", "N+")
					.replace("Nmas media", "N+ Media"),
			};
		});
		switch (typeRender) {
			case "talents": {
				crumblist.forEach((crumb: any) => {
					auxCrumblist.push({
						href:
							crumb.href === "/talentos" ? "/nmas-media/talentos" : crumb.href,
						text: crumb.text,
					});
				});
				break;
			}
			case "video": {
				crumblist.forEach((crumb: any) => {
					if (crumb.text === "programas" || crumb.text === "Programas") {
						auxCrumblist.push({
							href:
								crumb.href === "/talentos"
									? "/nmas-media/talentos"
									: crumb.href,
							text: crumb.text,
						});
					}
				});
				auxCrumblist.push({
					href: crumblist[crumblist.length - 1].href,
					text: crumblist[crumblist.length - 1].text,
				});
				break;
			}
			default: {
				auxCrumblist = crumblist;
			}
		}
		return [{ href: "/", text: "Inicio" }, ...auxCrumblist];
	};

	const Crumb = ({
		text,
		href,
		last = false,
		isActive = false,
		themeColor,
	}: any) => {
		// The last crumb is rendered in plain text since we are already on the page
		href = widthTrailingSlash(href);
		if (last) {
			if (getLasted && (specialPath === "" || specialPath === null)) {
				return (
					<span
						className={`
							${bloque["breadCrumb__titleBreadcrumbSpan"]}
							${
								isActive
									? bloque[
											"breadCrumb__titleBreadcrumbSpan--isActive-" + themeColor
									  ]
									: bloque[
											"breadCrumb__titleBreadcrumbSpan--notActive-" + themeColor
									  ]
							}
						`}
					>
						{text}
					</span>
				);
			}
			if (getLasted && specialPath !== "") {
				return (
					<span
						className={`
							${bloque["breadCrumb__titleBreadcrumbSpan"]}
							${
								isActive
									? bloque[
											"breadCrumb__titleBreadcrumbSpan--isActive-" + themeColor
									  ]
									: bloque[
											"breadCrumb__titleBreadcrumbSpan--notActive-" + themeColor
									  ]
							}
						`}
					>
						{specialPath}
					</span>
				);
			}
			return null;
		}

		// All other crumbs will be rendered as anchor that can be visited
		return (
			<>
				<a
					className={`
					${bloque["breadCrumb__titleBreadcrumb"]}
					${
						isActive
							? bloque["breadCrumb__titleBreadcrumb--isActive-" + themeColor]
							: bloque["breadCrumb__titleBreadcrumb--notActive-" + themeColor]
					}
				`}
					href={href}
				>
					{text}
				</a>
				<Icon
					name="arrowRight"
					className={`${bloque["breadCrumb__wrapper__iconRight"]} ${
						themeColor === "light"
							? bloque["breadCrumb__wrapper__iconRight--light"]
							: bloque["breadCrumb__wrapper__iconRight--dark"]
					}`}
				/>
			</>
		);
	};

	return (
		<div
			className={`${
				themeColor === "light"
					? bloque["breadCrumb__section"]
					: bloque["breadCrumb__sectionDark"]
			}`}
		>
			<div className={`${bloque["breadCrumb__wrapper"] + " breadcrumb"} `}>
				{generateBreadcrumbs(typeRender).map((crumb, idx) => {
					return (
						<Crumb
							{...crumb}
							themeColor={themeColor}
							key={`Breadcrumb-${idx}`}
							last={idx === auxCrumblist.length}
							isActive={idx !== auxCrumblist.length - 2 || getLasted}
						/>
					);
				})}
			</div>
		</div>
	);
}
