const swipperVideoPLaylistBrakpoints = (currentResolution: number) => {
	return {
		320: {
			slidesPerView: 1.5,
		},
		340: {
			slidesPerView: 1.5,
		},
		468: {
			slidesPerView: 1.9,
		},
		562: {
			slidesPerView: 1.9,
		},
		630: {
			slidesPerView: 2,
		},
		700: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 2.5,
		},
		853: {
			slidesPerView: 2.7,
		},
		1200: {
			slidesPerView: 3.5,
		},
	};
};

export default swipperVideoPLaylistBrakpoints;
