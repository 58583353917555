"use client";
import { useState, useRef, useEffect } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import useHandleResize from "@/hooks/useHandleResize";
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
import PlayArrowSharpIcon from "@mui/icons-material/PlayArrowSharp";
import swipperVideoPLaylistBrakpoints from "@/app/components/molecules/StoryPageMedia/helpers/swiperBrakpoints";
import style from "@/app/styles/Molecules/MultiVideo.module.scss";

const EndCard = dynamic(
	() => import("@/app/components/molecules/EndCard/EndCard")
);
const PlayerListVod = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const CardVideoStoryPage = dynamic(
	() =>
		import(
			"@/app/components/molecules/cards/CardVideoStoryPage/CardVideoStoryPage"
		)
);

type Props = {
	cardItem?: any;
	isOpening?: boolean;
	isTabToPlay?: boolean;
};

export default function MultiVideo({
	isOpening,
	cardItem,
	isTabToPlay = true,
}: Props) {
	const videosArray: any[] = cardItem ?? [];
	const singleImage = videosArray[0]?.image?.imageUrl ?? videosArray[0]?.cover;
	const playlistIds = videosArray.map((video: any) => {
		return { mcpId: video?.cmsid };
	});
	const swiperRef = useRef<any>(null);
	const currentResolution: number = useHandleResize() ?? 0;
	const [currentVideo, setCurrentVideo] = useState<any>(videosArray?.[0]);
	const [prevVod, setPrevVod] = useState<any>(videosArray?.[0]);
	const [nextVideo, setNextVideo] = useState<any>(videosArray?.[1]);
	const [currentIndexVideo, setCurrentIndexVideo] = useState<string>(
		playlistIds[0]?.mcpId || ""
	);
	const [showEndCard, setShowEndCard] = useState<boolean>(false);
	const [sliderIndex, setSliderIndex] = useState<number>(0);
	const [isMuted, setMuted] = useState<boolean>(true);
	const [isMobile, setIsMobile] = useState<boolean>(true);
	const [playVideo, setPlayVideo] = useState<boolean>(false);
	const updatedVod = (prevVod: any, newCurrent: any) => {
		setPrevVod(prevVod);
		setCurrentVideo(newCurrent);
	};

	const changeVideo = (indexVideo: number) => {
		const nextVideo = videosArray?.[indexVideo];

		setCurrentVideo(() => {
			updatedVod(currentVideo, {
				cmsid: nextVideo?.cmsid,
				title: nextVideo?.title,
				description: nextVideo?.description,
				duration: nextVideo?.duration,
				path: nextVideo?.path,
				image: {
					imageUrl: nextVideo?.image?.imageUrl,
					imageAlt: nextVideo?.image?.imageAlt,
				},
			});
			return nextVideo;
		});

		setShowEndCard(false);
		!isMuted && setMuted(false);
		setSliderIndex(indexVideo);
		if (indexVideo === videosArray?.length - 1) {
			setNextVideo(videosArray[0]);
		} else {
			setNextVideo(videosArray[indexVideo + 1]);
		}
		setCurrentIndexVideo(playlistIds[indexVideo]?.mcpId);
		swiperRef?.current?.swiper.slideTo(indexVideo);
	};

	const onEndedEndCardCount = () => {
		if (videosArray?.length > 1) {
			const index = videosArray?.findIndex((videoAtIndex) => {
				return videoAtIndex?.cmsid === currentVideo.cmsid;
			});

			if (index < videosArray?.length - 1) {
				changeVideo(index + 1);
			} else if (index === videosArray?.length - 1 || !videosArray) {
				changeVideo(0);
			}
		}
	};

	useEffect(() => {
		const mql = window?.matchMedia("(max-width: 420px)");
		setIsMobile(mql?.matches);
	}, []);

	return (
		<>
			<figure
				className={`
					${style["multiVideo__VideoWrapper"]}
					${showEndCard ? style["multiVideo__VideoWrapper--endCard"] : ""}
				`}
				onClick={() => setPlayVideo(true)}
			>
				{!playVideo && (
					<button
						type="button"
						className={`${style["multiVideo__playButton"]}`}
					>
						<PlayArrowSharpIcon />
					</button>
				)}
				<div
					style={
						showEndCard && videosArray?.length > 1 ? {} : { display: "none" }
					}
				>
					<EndCard
						actualVod={currentVideo}
						onEndedVideo={onEndedEndCardCount}
						videoList={videosArray}
						showEndCard={showEndCard}
					/>
				</div>
				{!playVideo && (
					<Image
						src={singleImage ?? "/assets/placeholder_.svg"}
						alt={
							cardItem?.description ||
							currentVideo?.image?.imageAlt ||
							currentVideo?.title ||
							"N+"
						}
						width={987}
						height={557}
						sizes="(min-width: 1440px) 58vw, (min-width: 1280px) 65vw, (min-width: 1024px) 45.2vw, (min-width: 768px) 45vw, (min-width: 720px) 40vw, (min-width: 430px) 37vw, (min-width: 390px) 35vw, (min-width: 375px) 50vw, 26vw"
						loading="lazy"
						// placeholder="blur"
						// blurDataURL={`${
						// 	process.env.NEXT_PUBLIC_BLURIMAGE ||
						// 	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						// }`}
						quality={60}
					/>
				)}
				{playVideo && (
					<PlayerListVod
						idVideo={currentVideo?.cmsid}
						prevVod={prevVod?.cmsid}
						autoplay
						mute={false}
						currentPlaceholder={currentVideo?.image?.imageUrl}
						onVideoEnded={() => setShowEndCard(true)}
					/>
				)}
			</figure>
			{currentVideo?.description?.length > 0 && (
				<div className={`${style["multiVideo__CaptionWrapper"]}`}>
					<p className={`${style["multiVideo__Caption"]}`}>
						{sanitizeText({
							text: currentVideo?.description || "",
							maxCharacters: 256,
						})}
					</p>
				</div>
			)}
			{isOpening && (
				<Swiper
					spaceBetween={8}
					freeMode={true}
					ref={swiperRef}
					navigation={true}
					watchSlidesProgress={true}
					modules={[FreeMode, Navigation, Thumbs]}
					className={`${style["multiVideo__SwiperAutoPlayVideo"]} ${style['multiVideo__SwiperAutoPlayVideo--padding']}`}
					breakpoints={swipperVideoPLaylistBrakpoints(currentResolution)}
				>
					{videosArray?.map((videoCard: any, index: number) => {
						return (
							<SwiperSlide
								style={{ width: "180px" }}
								key={`storyCard-${index}`}
							>
								<CardVideoStoryPage
									typeOfMedia={"multivideo"}
									video={videoCard}
									onChangeSlider={() => changeVideo(index)}
									setPlayVideo={() => setPlayVideo(true)}
									indexVideoCard={index}
									indexVideoPlay={sliderIndex}
									image={videoCard?.image?.imageUrl ?? videoCard?.cover}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}
		</>
	);
}
